
import { onMounted, defineComponent, ref, computed } from "vue"
import Button from "../components/Button.vue"
import Icon from "../components/Icon.vue"
import Footer from "../components/footer.vue"
import { useRoute, useRouter } from "vue-router"
import axios from "axios"
import { useStore } from "vuex"

export default defineComponent({
  components: {
    Button,
    Icon,
    Footer,
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const message = ref("")
    const store = useStore()
    let token = ""
    onMounted(() => {
      if (route.query.token != undefined) {
        token = route.query.token.toString()
      } else {
        router.push("/")
      }
    })
    const pass1 = ref("")
    const validpass = computed(() => {
      return pass1.value.length >= 8
    })
    const pass2 = ref("")
    const equalpass = computed(() => {
      return pass1.value == pass2.value && validpass.value
    })
    const url = "https://auth.otonomi.no"
    // const url = "http://localhost:8090"
    const reset = async () => {
      console.log("reset")
      if (validpass.value && equalpass.value) {
        await axios({
          baseURL: url,
          url: "/reset",
          data: { password: pass1.value },
          method: "POST",
          headers: {
            Authorization: "Bearer " + token,
          },
        })
          .then((resp) => {
            console.log(resp.status)
            setTimeout(() => {
              router.push("/")
            }, 5000)
          })
          .catch((err) => {
            message.value = "Somthing went wrong"
            console.log(err)
            setTimeout(() => {
              router.push("/")
              store.commit("setModal", 3)
            }, 1000)
          })
      }
    }
    return {
      pass1,
      pass2,
      validpass,
      equalpass,
      reset,
      message,
    }
  },
})

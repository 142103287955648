<script lang="ts">
import { onMounted, defineComponent, ref, computed } from "vue"
import Button from "../components/Button.vue"
import Icon from "../components/Icon.vue"
import Footer from "../components/footer.vue"
import { useRoute, useRouter } from "vue-router"
import axios from "axios"
import { useStore } from "vuex"

export default defineComponent({
  components: {
    Button,
    Icon,
    Footer,
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const message = ref("")
    const store = useStore()
    let token = ""
    onMounted(() => {
      if (route.query.token != undefined) {
        token = route.query.token.toString()
      } else {
        router.push("/")
      }
    })
    const pass1 = ref("")
    const validpass = computed(() => {
      return pass1.value.length >= 8
    })
    const pass2 = ref("")
    const equalpass = computed(() => {
      return pass1.value == pass2.value && validpass.value
    })
    const url = "https://auth.otonomi.no"
    // const url = "http://localhost:8090"
    const reset = async () => {
      console.log("reset")
      if (validpass.value && equalpass.value) {
        await axios({
          baseURL: url,
          url: "/reset",
          data: { password: pass1.value },
          method: "POST",
          headers: {
            Authorization: "Bearer " + token,
          },
        })
          .then((resp) => {
            console.log(resp.status)
            setTimeout(() => {
              router.push("/")
            }, 5000)
          })
          .catch((err) => {
            message.value = "Somthing went wrong"
            console.log(err)
            setTimeout(() => {
              router.push("/")
              store.commit("setModal", 3)
            }, 1000)
          })
      }
    }
    return {
      pass1,
      pass2,
      validpass,
      equalpass,
      reset,
      message,
    }
  },
})
</script>

<template>
  <div class="container">
    <!-- <transition name="fade"> -->
    <div class="modal">
      <h1 class="header">Reset password</h1>

      <div class="details">
        <p class="light-text">Fill inn your new password below.</p>

        <div class="form-group">
          <span class="label"><Icon>lock_open</Icon></span>
          <span v-if="validpass" class="approve"><Icon>check</Icon></span>
          <input
            required
            v-model="pass1"
            class="form-field"
            type="password"
            placeholder="Password"
          />
        </div>

        <div class="form-group">
          <span class="label"><Icon>lock_open</Icon></span>
          <span v-if="equalpass" class="approve"><Icon>check</Icon></span>
          <input
            required
            v-model="pass2"
            class="form-field"
            type="password"
            placeholder="Confirm Password"
          />
        </div>

        <br />

        <Button
          bgColor="var(--color-dark-green)"
          txtColor="#ffffff"
          shadow="var(--shadow-green)"
          @click="reset"
          >Reset</Button
        >
        <p>{{ message }}</p>
      </div>
    </div>
    <!-- </div> -->
    <!-- </transition> -->
  </div>
</template>

<style lang="scss" scoped>
@import "../css/home.scss";

.material-icons {
  display: flex;
}
.bottom-spacing {
  margin-bottom: 50px;
}
.container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.password {
  height: 0px;
  opacity: 0;
  display: none;
}
.fade-enter-active {
  transition: opacity 1s;
}
.fade-enter-from .modal {
  opacity: 0;
}
.fade-enter-to .modal {
  opacity: 1;
}
.fade-enter-active .modal {
  transition: opacity 500ms 500ms;
}
.fade-enter-from .blur {
  opacity: 0;
  backdrop-filter: blur(0px);
}
.fade-enter-to .blur {
  opacity: 1;
  backdrop-filter: blur(6px);
}
.fade-enter-active .blur {
  transition: backdrop-filter 500ms;
}
</style>
